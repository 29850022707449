.bouncing-balls {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100px;
    height: 100px;
margin-top: 5rem;
}

.bouncing-balls div {
    width: 200px;
    height: 100px;
    border-radius: 50%;
    animation: boucning 0.5s cubic-bezier(0.19, 0.57, 0.3, .98) infinite alternate;
}
.bouncing-balls div:nth-child(2){
    animation-delay: 0.1s;
    opacity: 0.8;
}
.bouncing-balls div:nth-child(3){
    animation-delay: 0.2s;
    opacity: 0.6;
}
.bouncing-balls div:nth-child(4){
    animation-delay: 0.3s;
    opacity: 0.4;
}
@keyframes boucning {
    from{
        transform: translateY(0);
    }
    to{
        transform: translateY(-80%);
    }
}